/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Sidebar menu functions
        var sidebarMenu = $('#sidebar-menu'),
            sidebarMenuToggle = $('#sidebar-menu-toggle');

        sidebarMenuToggle.on('click', function() {
          $(this).toggleClass('active');
          sidebarMenu.toggleClass('active');
        });

        $('.sidebar-menu-close').on('click', function() {
          sidebarMenu.toggleClass('active');
          sidebarMenuToggle.toggleClass('active');
        });

        //Workshop sidebar stuff
        $('#lesson-menu-toggle').on('click', function() {

            $('.toggleable-lesson-sidebar').slideToggle();

        });


        //Registration
        var registrationForm = $('#register-form'),
            password = $('#register-password'),
            confirmPassword = $('#register-password-confirm');

        //Check that passwords match
        function validatePassword(password, confirm_password){
            if(password.value !== confirm_password.value) {
                confirm_password.setCustomValidity(CatalyticTraining.password_match_error);
            } else {
                confirm_password.setCustomValidity('');
            }
        }

        confirmPassword.on('keyup change', function(e) {
          validatePassword(password[0], confirmPassword[0]);
        });

        registrationForm.on('submit', function(e) {

          e.preventDefault();
          var registerResults = $('.register-results');

          var data = {
            action: 'catalytic_register_user',
            user: {
                username: registrationForm.find('#register-username').val(),
                email: registrationForm.find('#register-email').val(),
                password: registrationForm.find('#register-password').val(),
                date_of_birth: registrationForm.find('#date-of-birth').val(),
                gender: registrationForm.find('#register-gender').val(),
                nonce: registrationForm.find('#register-nonce').val()
            }
          };

          //Send to WP, redirect
          $.post(CatalyticTraining.ajax_url, data, function(response) {

              registerResults.html(response.data.message);

              if(response.error) {
                registerResults.addClass('text-danger');
                registerResults.removeClass('text-success');
              } else {
                  registerResults.removeClass('text-danger');
                  registerResults.addClass('text-success');

                  //Redirect back to home, logged in (for now)
                  setTimeout(function() {
                      location.reload();
                  }, 3000);

              }
          });
        });

        var loginForm = $('#login-form'),
          loginResults = $('.login-message');

        loginForm.on('submit', function(e) {
          e.preventDefault();

          var data = {
              action: 'catalytic_custom_login',
              user: {
                  username: loginForm.find('#login-email').val(),
                  password: loginForm.find('#login-password').val(),
                  nonce: loginForm.find('#login-nonce').val()
              }
          };

          //Send to WP, redirect
          $.post(CatalyticTraining.ajax_url, data, function(response) {

              loginResults.html(response.data.message);

              if(response.error) {
                  loginResults.addClass('text-danger');
                  loginResults.removeClass('text-success');
              } else {
                  loginResults.removeClass('text-danger');
                  loginResults.addClass('text-success');

                  //Redirect back to home, logged in (for now)
                  setTimeout(function() {
                      location.reload();
                  }, 3000);

              }
          });

        });

        var resetForm = $('#reset-password-form'),
            resetMessage = $('#reset-message');

        resetForm.on('submit', function(e) {
          e.preventDefault();

          var data = {
              action: 'catalytic_reset_password',
              user: {
                  email: resetForm.find('#reset-password-email').val(),
                  nonce: resetForm.find('#reset-password-nonce').val()
              }
          };

          //Send to WP, redirect
          $.post(CatalyticTraining.ajax_url, data, function(response) {
              if(response.success) {
                resetMessage.addClass('text-success');
                resetMessage.show();
              }
          });

        });

        //Course sidebar
        var courseMenu = $('#course-menu');

        if(courseMenu.length) {

            var courseMenuImmediateChildren = courseMenu.children('li').find('span');

            courseMenuImmediateChildren.on('click', function() {

                $(this).children('ul').slideToggle();
                $(this).parent('li').toggleClass('active');

            });
        }

        $('#save-response').on('click', function(e) {

            var answers = $('.answer-entry'),
                answersObj = {},
                submitButton = $(this),
                originalText = submitButton.html();

            if(answers.length) {
                answers.each(function() {
                    answersObj[$(this).attr('id')] = $(this).val();
                });
            }

            submitButton.html(CatalyticTraining.sign_up_message_after_click);

            var data = {
                action: 'catalytic_submit_response',
                submission: {
                    module_id: $('#module_id').val(),
                    workshop_id: $('#workshop_id').val(),
                    answers: answersObj,
                    nonce: $('#workshop_nonce').val()
                }
            };

            //Send to WP
            $.post(CatalyticTraining.ajax_url, data, function(response) {

                submitButton.html(CatalyticTraining.sign_up_message_after_submit);

                setTimeout(function(){
                    submitButton.html(originalText);
                }, 3000);

            });

        });

        var playableLibraryItem = $('.library-item-playable');

        playableLibraryItem.find('.library-item-icon').on('click', function() {
            //$(this).parent('.overlay').parent('.library-item').toggleClass('playing');

            var libraryModal  = $('#library-modal'),
                codeToEmbed = $(this).parent('.overlay').parent('.library-item').find('.embed-code-holder').val();

            libraryModal.find('.modal-body .video-area').html(codeToEmbed.trim());
            libraryModal.modal();
        });

        $('.library-item-type-audio').on('click', function(e) {

            e.preventDefault();

            var audioHolder = $('.audio-holder'),
                audioFile = $(this).find('a').attr('href'),
                currentSource = audioHolder.attr('src');

            if(audioFile !== currentSource) {
                audioHolder.attr('src', audioFile);
            }

            if (audioHolder[0].paused === false) {
                audioHolder[0].pause();
            } else {
                audioHolder[0].play();
            }

        });

        var profileImageInput = $('.profile-image-input');

        if(profileImageInput.length) {
          $('.faux-profile-input-button').on('click', function() {

              $('.profile-image-input').click();

          });

          profileImageInput.on('change', function(e) {

              $('.faux-profile-input-text').html($(this).val().split('\\').pop());

          });
        }

        var changePasswordForm = $('.change-password-form');

        if(changePasswordForm.length) {

            var matchError = $('.password-match-error');

            changePasswordForm.on('submit', function(e) {

                if(changePasswordForm.find('input[name=new_password]').val() !== changePasswordForm.find('input[name=new_password_confirm]').val()) {
                    e.preventDefault();
                    matchError.addClass('show');
                } else {
                    matchError.removeClass('show');
                }

            });
        }

        var libraryItems = $('.library-item');

        function refresh_library_pagination() {

            var libraryPagination = $('#library-pagination');
            var visibleLibraryItems = libraryItems.filter(function() {
                if(!$(this).hasClass('hide-module') && !$(this).hasClass('hide-search')) {
                    return true;
                }
            });

            libraryPagination.twbsPagination('destroy');

            var libraryItemsPerPage = 12;
            var totalPages = Math.ceil(visibleLibraryItems.length / libraryItemsPerPage);

            libraryPagination.twbsPagination({
                totalPages: totalPages,
                visiblePages: 3,
                onPageClick: function (event, page) {
                    var startItem = libraryItemsPerPage*(page-1);
                    var visibleItems = visibleLibraryItems.slice(startItem, startItem + libraryItemsPerPage);
                    libraryItems.removeClass('show-paging');
                    visibleItems.addClass('show-paging');
                }
            });
        }

        if(libraryItems.length > 0) {

            var libraryPagination = $('#library-pagination');
            var libraryItemsPerPage = 12;

            var totalPages = Math.ceil(libraryItems.length / libraryItemsPerPage);

            libraryPagination.twbsPagination({
                totalPages: totalPages,
                visiblePages: 3,
                onPageClick: function (event, page) {
                    var startItem = libraryItemsPerPage*(page-1);
                    var visibleItems = libraryItems.slice(startItem, startItem + libraryItemsPerPage);
                    libraryItems.removeClass('show-paging');
                    visibleItems.addClass('show-paging');
                }
            });

        }

        $('#select_file_types').on('change', function() {

            var currentSelection = $(this).val();

            if(libraryItems.length) {
                libraryItems.each(function() {

                    if(currentSelection.length === 0) {
                        $(this).removeClass('hide-file');
                    } else {
                        if(typeof $(this).data('type') !== 'undefined') {
                            if($(this).data('type').toString() !== currentSelection.toString()) {
                                $(this).addClass('hide-file');
                            } else {
                                $(this).removeClass('hide-file');
                            }
                        }
                    }

                });
            }

            refresh_library_pagination();
        });

        $('#select_modules').on('change', function() {

            var currentSelection = $(this).val();

            if(currentSelection.length === 0) {
                libraryItems.removeClass('hide-module');
            }

            if(libraryItems.length) {

                libraryItems.each(function() {

                    if(typeof $(this).data('module') !== 'undefined') {
                        if($(this).data('module').toString() !== currentSelection.toString()) {
                            $(this).addClass('hide-module');
                        } else {
                            $(this).removeClass('hide-module');
                        }
                    }
                });
            }

            refresh_library_pagination();
        });

        $('#library_keyword_search').on('change keyup', function() {

            var searchTerm = $(this).val();
            var title = '';

            libraryItems.each(function() {

                title = $(this).find('.library-item-title').html();

                if(typeof title !== 'undefined' && title.length) {
                    if((title.indexOf(searchTerm) === -1)) {
                        $(this).addClass('hide-search');
                        $(this).parent().addClass('hide-search-parent');
                    } else {
                        $(this).removeClass('hide-search');
                        $(this).parent().removeClass('hide-search-parent');
                    }
                } else {
                    //Remove the comment from the following line if you want search results to show items with no titles
                    $(this).addClass('hide-search');
                    $(this).parent().removeClass('hide-search-parent');
                }


            });

            refresh_library_pagination();
        });

        var libraryMenu = $('#library_menu');

        if(libraryMenu.length) {

            var libraryMenuItems = libraryMenu.find('li');

            libraryMenuItems.on('click', function() {

                var module = $(this).data('module');

                libraryItems.each(function() {

                    if(typeof module !== 'undefined') {
                        if(typeof $(this).data('module') !== 'undefined') {

                            if(module.toString() !== $(this).data('module').toString()) {
                                $(this).addClass('hide-module');
                            } else {
                                $(this).removeClass('hide-module');
                            }
                        } else {
                            $(this).removeClass('hide-module');
                        }
                    } else {
                        libraryItems.removeClass('hide-module');
                    }
                });

                refresh_library_pagination();

            });


        }
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Account settings page
    'account_settings': {
      init: function() {




      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
